import { classnames } from "@lib/tools/helpers";
import tablecss from "@styles/components/Table.module.scss";

const Table = ({ head, hidden, body, width = "100%", children }) => {
  return (
    <div
      data-hidden={hidden}
      className={tablecss["table"]}
      style={{
        "--width": width,
      }}
    >
      <div {...classnames(tablecss, "row", "head")}>{head}</div>

      {children || body || null}
    </div>
  );
};

export default Table;
