import Image from "next/image";
import st from "../styles/games/GameUi.module.scss";
import useMobile from "../hooks/useMobile";
import Button from "../items/Button";
import { useMemo, useState } from "react";
import {
  capitalize,
  classnames,
  formatDateMonthDayYear,
  round2Decimal,
  useLocale,
} from "../lib/tools/helpers";
import Icon from "../assets/icons/leaderboard/Icon";
import ValueDisplay from "../items/ValueDisplay";
import useLanguage from "../hooks/useLanguage";
import GamesIcons from "../items/GamesIcons";
import { usePathname } from "next/navigation";
import Link from "next/link";
import RewardIcons from "../items/RewardIcons";
import ToolTip from "../items/ToolTip";
import Table from "../components/Table";
import DOMPurify from "dompurify";
import Hidden from "@assets/icons/general/Hidden";

const TABS = {
  description: "description",
  instructions: "instructions",
  top_wins: "top_wins",
  luckiest_wins: "luckiest_wins",
};

const POSITION_WIDTH = 80;

const Head = () => {
  const L = useLanguage(["common", "bets"]);
  const isMobile = useMobile({});
  const mobile2 = isMobile === "mobile-two";

  return (
    <>
      <div className="max-w-20">
        <div style={{ width: 58, textAlign: "center" }}>#</div>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
        }}
      >
        {L("user")}
      </div>
      {!mobile2 && <div>{L("betAmount")}</div>}
      <div>{L("multiplier")}</div>
      <div>{L("payout")}</div>
      {!isMobile && (
        <div
          style={{
            justifyContent: "flex-end",
            paddingRight: 16,
          }}
        >
          {L("date")}
        </div>
      )}
    </>
  );
};

const BodyItem = (item) => {
  const locale = useLocale();
  const L = useLanguage("common");
  const pathname = usePathname();
  const isMobile = useMobile();
  const mobile2 = isMobile === "mobile-two";

  return (
    <Link
      shallow
      replace
      href={{
        pathname,
        query: {
          modal: "bet",
          tab: "result",
          betId: item.id,
        },
      }}
    >
      <div className="max-w-20">
        <Icon p={item.p} />
      </div>
      <div className="!justify-start gap-1.5">
        <ToolTip
          text={
            item?.rank &&
            `${capitalize(item?.rank)} ${item?.division.toLocaleUpperCase()}`
          }
        >
          {item?.rank && (
            <RewardIcons code={item?.rank?.toUpperCase()} size={"xlarge"} />
          )}
        </ToolTip>
        {item.username || (
          <span className="text-font-color-seven [&_svg]:opacity-50 flex items-center gap-2">
            {L("hidden")}
            <Hidden />
          </span>
        )}
      </div>

      {!mobile2 && (
        <div>
          <ValueDisplay
            amount={item?.currency_amount}
            size={"mid"}
            currencyCode={item?.currency}
          />
        </div>
      )}
      <div className="text-font-color-seven">
        {round2Decimal(item.multiplier) + "x"}
      </div>
      <div className="text-secondary-stroke-color">
        <ValueDisplay
          amount={item.currency_payout}
          size={"mid"}
          currencyCode={item?.currency}
        />
      </div>
      {!isMobile && (
        <div className="flex !justify-end pr-4 text-font-color-seven">
          {formatDateMonthDayYear(item.timestamp, locale)}
        </div>
      )}
    </Link>
  );
};

const filterTable = (gameDescription) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = gameDescription;

  // Get all <tr> elements from the table inside 'gameDescriptionContent'
  const rows = tempDiv.querySelectorAll("tr");

  // Iterate over each row
  rows.forEach((row) => {
    const cells = row.querySelectorAll("td");

    // Iterate through the cells in the row
    for (let i = 0; i < cells.length; i++) {
      let currentCell = cells[i];

      // Check if the current cell has content
      if (currentCell.textContent.trim() !== "") {
        let colspan = 1;

        // Iterate through the subsequent cells
        for (let j = i + 1; j < cells.length; j++) {
          let nextCell = cells[j];
          // Check if the next cell is empty
          if (nextCell.textContent.trim() === "") {
            colspan++; // Increase the colspan value
            nextCell.style.display = "none"; // Hide the empty cell
          } else {
            break; // Stop if the next cell has content
          }
        }
        // Set the updated colspan for the current cell
        if (colspan > 1) {
          currentCell.setAttribute("colspan", colspan);
        }
      }
    }
  });
  return tempDiv.innerHTML;
};
export default function GameDescription({
  gameDetails = {},
  gameImage,
  meta = {},
  mobileSlotDisplay,
}) {
  const isMobile = useMobile({ breakPointThree: 600 });

  const topwins = meta?.statistics?.top_wins;
  const luckiestwins = meta?.statistics?.luckiest_wins;
  const defaultTab = topwins?.length
    ? TABS.top_wins
    : luckiestwins?.length
      ? TABS.luckiest_wins
      : TABS.description;
  const [gameDescriptionType, setGameDescriptionType] = useState(defaultTab);
  const { content, heading, content_2 } = gameDetails;
  let gameDescriptionContent = content;

  if (typeof window !== "undefined") {
    const purify = DOMPurify(window);
    purify.setConfig({
      FORBID_TAGS: ["colgroup"],
    });

    // Sanitize the HTML
    gameDescriptionContent = filterTable(purify.sanitize(content));
  }
  const L = useLanguage(["common", "UserDisplay"]);

  const [body1, body2] = useMemo(
    () => [
      topwins?.map((item, i) => <BodyItem {...item} p={i + 1} key={i} />),
      luckiestwins?.map((item, i) => <BodyItem {...item} p={i + 1} key={i} />),
    ],
    [topwins, luckiestwins]
  );

  const WIDTH = `calc((100% - ${POSITION_WIDTH}px) / 5)`;

  const text = {
    big_wins: L("big_wins"),
    lucky_wins: L("lucky_wins"),
    description: L("description"),
  };

  const displayMobileSlotMenu = useMemo(
    () =>
      !!mobileSlotDisplay ? (
        <div className={st["mobile-details-display"]}>{mobileSlotDisplay}</div>
      ) : null,
    [mobileSlotDisplay]
  );

  const gameNameCount = () => {
    return (
      <>
        {meta?.type && (
          <div {...classnames(st, "count__type")}>
            <GamesIcons code={meta?.type} size={"small"} />
            <p>{capitalize(meta?.type)}</p>
          </div>
        )}
        {meta?.title && !displayMobileSlotMenu && (
          <p {...classnames(st, "count__title")}>{meta?.title}</p>
        )}

        {displayMobileSlotMenu && (
          <h1 className="text-[13px] mb-5 leading-[1.4]">{heading}</h1>
        )}

        {/* <h2>{heading}</h2> */}
        {meta?.statistics?.round_count > 0 && (
          <p {...classnames(st, "count__value")}>
            {L("total_bets")}
            <span>{meta?.statistics?.round_count?.toLocaleString()}</span>
          </p>
        )}
      </>
    );
  };

  return displayMobileSlotMenu || gameDetails ? (
    <div
      {...classnames(
        st,
        "game-description",
        isMobile,
        !!mobileSlotDisplay && "game-description__slot-mobile-display"
      )}
    >
      {gameDetails ? (
        <>
          <div {...classnames(st, "game-image-container")}>
            <div className={st["game-image"]}>
              {gameImage && (
                <Image
                  alt="game image"
                  src={gameImage}
                  sizes="100vw"
                  width={140}
                  height={185}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              )}
            </div>

            <div className={st["meta-container"]}>
              {meta?.title && (
                <div {...classnames(st, "count")}>
                  <hr
                    style={{
                      marginTop: 24,
                      marginBottom: 18,
                    }}
                  />
                  {gameNameCount()}
                </div>
              )}

              {isMobile !== "mobile-three" && displayMobileSlotMenu}
            </div>
          </div>
          <div
            {...classnames(
              st,
              "game-description-bottom-container"
              // isMobile == "mobile-two" && "mobile-version",
              // isMobile == "mobile-three" && "mobile-version",
            )}
          >
            {isMobile === "mobile-three" && displayMobileSlotMenu}
            {/* {displayMobileSlotMenu} */}
            {/* {gameNameCount()} */}
            {!displayMobileSlotMenu && <h1>{heading}</h1>}
            {content || content_2 ? (
              <div className={st["game-description-bottom-buttons"]}>
                {body1?.length ? (
                  <Button
                    method={() => {
                      setGameDescriptionType(TABS.top_wins);
                    }}
                    text={text.big_wins}
                    classType={["tab", "live-tab"]}
                    active={gameDescriptionType === TABS.top_wins}
                  />
                ) : null}

                {body2?.length ? (
                  <Button
                    method={() => {
                      setGameDescriptionType(TABS.luckiest_wins);
                    }}
                    text={text.lucky_wins}
                    classType={["tab", "live-tab"]}
                    active={gameDescriptionType === TABS.luckiest_wins}
                  />
                ) : null}

                {content_2 && (
                  <Button
                    method={() => {
                      setGameDescriptionType(TABS.instructions);
                    }}
                    text={"How to Play"}
                    classType={["tab", "live-tab"]}
                    active={gameDescriptionType === TABS.instructions}
                  />
                )}

                {content && (
                  <Button
                    method={() => {
                      setGameDescriptionType(TABS.description);
                    }}
                    text={text.description}
                    classType={["tab", "live-tab"]}
                    active={gameDescriptionType === TABS.description}
                  />
                )}
              </div>
            ) : null}
            <Table
              width={WIDTH}
              hidden={gameDescriptionType !== TABS.top_wins || !body1?.length}
              body={body1}
              head={<Head />}
            />
            <Table
              width={WIDTH}
              hidden={
                gameDescriptionType !== TABS.luckiest_wins || !body2?.length
              }
              body={body2}
              head={<Head />}
            />
            <div
              data-hidden={gameDescriptionType !== TABS.description || !content}
              dangerouslySetInnerHTML={{ __html: gameDescriptionContent }}
              className={st["game-content"]}
            />
            <div
              data-hidden={
                gameDescriptionType !== TABS.instructions || !content_2
              }
              dangerouslySetInnerHTML={{ __html: content_2 }}
              className={st["game-content"]}
            />
          </div>
        </>
      ) : displayMobileSlotMenu ? (
        displayMobileSlotMenu
      ) : null}
    </div>
  ) : null;
}
